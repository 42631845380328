<template>
  <div class="jumbotron sitemap">
    <b-container class="mt-4">
      <h1 class="my-4">{{ title }}</h1>
      <div class="">
        <ul class="menu">
          <template v-for="menusList in menus">
            <li :key="`sitemap_${menusList.id}`" class="first-menu" v-if="menusList.title">
              <b-link
                rel=""
                :href="menusList.url"
                :to="menusList.url ? '' : menusList.path"
                v-if="menusList.chilrends.length > 0"
                >{{ menusList.title }}</b-link
              >
              <b-link rel="" :to="menusList | url(lang)" v-else>{{ menusList.title }}</b-link>
              <template v-if="menusList.chilrends.length > 0">
                <ul class="mx-3">
                  <template v-for="menusListChildrends in menusList.chilrends">
                    <li
                      v-if="menusListChildrends.title"
                      class="second-menu"
                      :key="`sitemap_sub_${menusListChildrends.id}`"
                    >
                      <b-link
                        rel=""
                        :href="menusListChildrends.url"
                        :to="menusListChildrends.url ? '' : menusListChildrends.path"
                        >{{ menusListChildrends.title }}</b-link
                      >
                      <template v-if="menusListChildrends.chilrends.length > 0">
                        <ul class="mx-3">
                          <template v-for="menusListChildrends2 in menusListChildrends.chilrends">
                            <li
                              class="second-menu"
                              v-if="menusListChildrends2.title"
                              :key="`sitemap_sub2_${menusListChildrends2.id}`"
                            >
                              <b-link
                                rel=""
                                :href="menusListChildrends2.url"
                                :to="menusListChildrends2.url ? '' : menusListChildrends2.path"
                                >{{ menusListChildrends2.title }}</b-link
                              >
                            </li>
                          </template>
                        </ul>
                      </template>
                    </li>
                  </template>
                </ul>
              </template>
            </li>
          </template>
        </ul>
        <ul class="menu foot-menu">
          <template v-for="footersList in footer">
            <li :key="`sitemap_${footersList.id}`" class="first-menu" v-if="footersList.title">
              <b-link rel="" :href="footersList.url" :to="footersList.url ? '' : footersList.path">{{
                footersList.title
              }}</b-link>
              <template v-if="footersList.chilrends.length > 0">
                <ul class="mx-3">
                  <li
                    class="second-menu"
                    v-for="footersListChildrends in footersList.chilrends"
                    :key="`sitemap_sub_${footersListChildrends.id}`"
                  >
                    <b-link
                      rel=""
                      :href="footersListChildrends.url"
                      :to="footersListChildrends.url ? '' : footersListChildrends.path"
                      >{{ footersListChildrends.title }}</b-link
                    >
                    <template v-if="footersListChildrends.chilrends.length > 0">
                      <ul class="mx-3">
                        <li
                          class="second-menu"
                          v-for="footersListChildrends2 in footersListChildrends.chilrends"
                          :key="`sitemap_sub2_${footersListChildrends2.id}`"
                        >
                          <b-link
                            rel=""
                            :href="footersListChildrends2.url"
                            :to="footersListChildrends2.url ? '' : footersListChildrends2.path"
                            >{{ footersListChildrends2.title }}</b-link
                          >
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </template>
            </li>
          </template>
        </ul>
      </div>
    </b-container>
  </div>
</template>

<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  data() {
    return {
      chartData: [],
      menus: null,
      footer: null,
      title: ''
    }
  },
  computed: {
    ...mapState('common', ['menu', 'footers', 'page']),
    alreadyInited: function() {
      return !!this.menu && !!this.page && !!this.footers
    },
    lang() {
      return ISO639ToLan(this.$getLocale())
    }
    // lang() {
    //   return lanToISO639(this.$getLocale())
    // }
  },
  watch: {},
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {
      this.menus = this.menu
      this.footer = this.footers.menu2
      this.title = this.page.title
    },
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  serverPrefetch() {
    return this.fetchData()
  },
  filters: {
    url: function(menus, lang) {
      if (menus.url) {
        return menus.url
      } else if (menus.path) {
        return menus.path == 'home' ? `/${lang}/` : `/${lang}/${menus.path}`
      } else {
        return 'javascript:void(0)'
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  head() {
    return {
      title: '市場資訊 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
